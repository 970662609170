.tarjetaUniversidad .carta {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 0 solid #eff2f7;
    border-radius: 0.3rem;
}

.tarjetaUniversidad .carta .cuerpo {
    flex: 1 1 auto;
    text-align: center;
}

.tarjetaUniversidad .carta .cuerpo .cajaIlustracion {
    height: 18rem;
    width: 20rem;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-weight: 600;
    overflow: hidden;
    border-radius: 50%;
}

.tarjetaUniversidad .titulo {
    margin-bottom: 0.25rem;
    font-weight: 600;
    font-size: 18px;
}

.tarjetaUniversidad .descripcion {
    font-size: 15px;
}