.resumenEstadisticas {
    z-index: 100;
    margin: 0 auto;
    border-radius: 15px;
    width: 70%;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.resumenEstadisticas .contenedor {
    display: block;
}
