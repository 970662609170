
.tarjetaEstadistica {
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 0 solid #eff2f7;
    border-radius: 0.3rem;
    flex: 1 1 auto;
    padding: 1.75rem 1.75rem;
    text-align: center;
}

.tarjetaEstadistica .titulo {
    font-weight: 600;
    font-size: 25px;
}

.tarjetaEstadistica .descripcion {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
}