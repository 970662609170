.margenBeneficios {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.tituloBeneficios {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 35px;
    font-weight: 600;
}

.seccionBackgroundBeneficios {
    background-color: #F8F8F8;
}

.contenedorBotonBeneficios {
    display: flex;
    flex: 1;
    justify-content: center;
}

.botonBeneficios {
    font-size: 20px;
    background-color: #00A82D;
    border-color: #00A82D;
}

.botonBeneficios:hover {
    background-color: #14CC45;
    border-color: #14CC45;
}