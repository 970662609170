.margenTablaComparativa {
    padding-top: 3.5rem;
}

.contenedorTitulo {
    width: 100%;
}

.tituloVideo {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
}

.contenedorVideo {
    width: 100%;
    justify-content: center;
}

.videoCentrado {
    display: block;
    margin: auto;
}

.imagenDetalleIcono {
    margin: 0.5rem;
    max-width: 36px;
    max-height: 36px;
}

.contenedorResumen {
    margin-left:auto; 
    text-align: center;
    margin-right:auto;
}

.seccionBackgroundTablaComparativa {
    background-color: white;
}