.imagenIcono {
    max-width: 36px;
    max-height: 36px;
}

.tablaComparativa {
    border: 1px solid;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
    width: 1000px;
    max-width: 95%;
}

.celdaCuerpo {
    text-align: center;
    vertical-align: middle;
    border: 1px solid;
    min-width: 160px;
}

.celdaCuerpoCaracteristicas {
    font-weight: 600;
    border: 1px solid;
}

.celdaCabeza {
    text-align: center;
    vertical-align: middle;
    border: 1px solid;
    overflow: hidden;
    min-width: 160px;
}

.celdaCabezaLetra {
    font-weight: 600;
    font-size: 18px;
    color: #0000AA;
    overflow-wrap: break-word;
}

.celdaCuerpoLetra {
    font-size: 15px;
    overflow-wrap: break-word;
}

.contenedorPalabraIcono {
    display: flex;
    flex: 1;
    align-items: center;
}

.fraseCaracteristicas {
    width: 90%;
}

@media (max-width: 1360px) {
    .celdaCabezaLetra {
        font-weight: 600;
        font-size: 16px;
        color: #0000AA;
        overflow-wrap: break-word;
    }

    .celdaCuerpoLetra {
        font-size: 14px;
        overflow-wrap: break-word;
    }
}

@media (max-width: 720px) {
    .celdaCabezaLetra {
        font-weight: 600;
        font-size: 15px;
        color: #0000AA;
        overflow-wrap: break-word;
    }

    .celdaCuerpoLetra {
        font-size: 13px;
        overflow-wrap: break-word;
    }
}

@media (max-width: 350px) {
    .celdaCabezaLetra {
        font-weight: 600;
        font-size: 10px;
        color: #0000AA;
        overflow-wrap: break-word;
    }

    .celdaCuerpoLetra {
        font-size: 8px;
        overflow-wrap: break-word;
    }
}