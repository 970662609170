.dimensionesRedes {
    height: 50px;
    width: 50px;
}

@media (max-width: 1359px) {
    .dimensionesRedes {
        height: 40px;
        width: 40px;
    }
}

@media (max-width: 639px) {
    .dimensionesRedes {
        height: 30px;
        width: 30px;
    }
}