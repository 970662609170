.imagenLogoEducation {
    width: 260px;
    height: 78px;
}

.navBarPermanente {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.contenedorEvitarOverlay {
    margin-top: 147px;
}