.margenEmpresa {
    margin-top: 3.5rem;
}

.detallesEmpresa {
    margin-top: 1rem;
    font-size: 15px;
}

.imagenFooter {
    width: 261px;
}