.tarjetaTestimonio .carta {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    border: 0 solid #eff2f7;
    border-radius: 0.3rem;
}

.tarjetaTestimonio .carta .cuerpo {
    flex: 1 1 auto;
    text-align: center;
}

.tarjetaTestimonio .carta .cuerpo .cajaIlustracion {
    height: 10rem;
    width: 10rem;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-weight: 600;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.tarjetaTestimonio .nombreTestimonio {
    font-weight: 600;
    font-size: 18px;
}

.contenedorNombreTestimonio {
    margin-top: 10px;
}

.tarjetaTestimonio .descripcion {
    font-size: 15px;
    font-style: italic;
    word-wrap: break-word;
}

.botonTestimonio {
    font-size: 15px;
    width: 50%;
    margin: 1rem;
    background-color: transparent;
    border-color: #265CDC;
    color: #265CDC
}

.contenedorCargo {
    margin-top: 10px;
}

.contenedorTarjetasTestimonios {
    display: flex;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}