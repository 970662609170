.margenNuestrosClientes {
    margin-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.nuestrosClientes .contenedor {
    margin-bottom: 2rem;
}

.tituloNuestrosClientes {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
}

.seccionBackgroundNuestrosClientes {
    background-color: white;
}