.contenedorFormulario {
    width: 150%
}

.contenedorPoliticas {
    margin-top: 1.5rem;
}

.tamanhoLetraPolitica {
    font-size: 12px;
}