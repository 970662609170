.contenedorNumeroTarjetaBeneficios {
    font-size: 30px;
}

.tituloTarjetaBeneficios {
    padding-top: 1rem;
    margin-bottom: 0rem;
    font-size: 15px;
    font-weight: 600;
}

.contenedorTarjetasBeneficios {
    display: flex;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}