.tituloTarjetaImagen {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 40px;
    font-weight: 600;
}

.subtituloTarjetaImagen {
    margin-bottom: 1.5rem;
    font-size: 22px;
}

.textoTarjetaImagen {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 2rem;
}

.imagenPortada {
    max-height: 426px;
    border-radius: 25px;
}

.margenContenedorInicio {
    margin-top: 3.5rem;
}