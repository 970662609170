.contenedorNumeroTarjeta {
    font-size: 30px !important;
}

.numeroTarjeta {
    font-size: 30px !important;
    font-style: normal;
}

.tituloTarjeta {
    padding-top: 1rem;
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
}

.descripcionTarjeta {
    margin-bottom: 1rem;
    margin-right: auto;
    margin-left: auto;
    font-size: 15px;
}

.contenedorTarjetas {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.letraNegrilla {
    font-weight: 600;
}