.margenTestimonios {
    margin-top: 3.5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.testimonios .contenedor {
    margin-bottom: 2rem;
}

.tituloTestimonios {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
}

.contenedorBotonTestimonios {
    display: flex;
    flex: 1;
    justify-content: center;
}

.botonTestimonios {
    font-size: 18px;
    width: 50%;
    margin: 0 auto;
}

.seccionBackgroundTestimonios {
    background-color: #F8F8F8;
}

.contenedorTarjetasTestimonios {
    display: flex;
    flex: 1;
    justify-content: center;
}

.botonTestimonios {
    font-size: 20px;
    background-color: #00A82D;
    border-color: #00A82D;
}

.botonTestimonios:hover {
    background-color: #14CC45;
    border-color: #14CC45;
}