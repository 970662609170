.margenUniversidades {
    margin-top: 3.5rem;
}

.universidades .contenedor {
    margin-bottom: 2rem;
}

.tituloUniversidades {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
}

.seccionBackgroundUniversidades {
    background-color: white;
}

.contenedorBotonUniversidades {
    display: flex;
    flex: 1;
    justify-content: center;
}

.referenciaBotonUniversidades {
    width: 40%;
}

.botonUniversidades {
    font-size: 20px;
    background-color: #00A82D;
    border-color: #00A82D;
}

.botonUniversidades:hover {
    background-color: #14CC45;
    border-color: #14CC45;
}

