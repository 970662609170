.margenFunciones {
    margin-top: 3.5rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}

.tituloFunciones {
    position: relative;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 35px;
    font-weight: 600;
}

.seccionBackgroundFunciones {
    background-color: #F8F8F8;
}