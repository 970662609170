.contenedorFormulario {
    display: flex;
    flex: 1 auto;
}

.textoCampos {
    color: black;
    text-align: left;
}

.contenedorBotonCentrado {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenedorBoton {
    height: 200px;
    position: relative;
    border: 3px solid green;
}

.centradoVertical {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.flechaSelect {
    cursor: pointer !important;
    background-image: url("../../../iconos/down-arrow.png");
    background-repeat: no-repeat;
    background-position: 95% center;
}

.campoOrigen {
    display: none;
}