.barraLateralPermanente {
    position: fixed;
    top: 60%;
    right: -30px;
    width: 120px;
    display: block;
    justify-content: center;
    z-index: 100;
    background-color: transparent;
}

.contenedorRedes {
    display: flex;
    justify-content: center;
    background-color: transparent;
}