.bloqueInformacionEmpresa{
    margin-top: 28px;
}

.tituloContactoInformacionEmpresa{
    margin-bottom: 1rem;
    font-size: 18px;
}

.mediosContactoInformacionEmpresa{
    flex: 1;
    padding-left: 1rem;
    font-size: 15px;
}