.tituloInicioNuevo {
    padding-bottom: 1rem;
    margin: 0;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
}

.backgroundInicio {
    background-color: #5F8DDA;
}